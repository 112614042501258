:root {
  --main-dark-blue: #004080;
  --main-light-blue: #99ccff;
  --main-blue: #4da6ff;
  /* --light:#ffe5e5 */
  --light: #f0f3f5;
  --main-chosen-sidebar: #b3b3ff;
  --primary: #99ccff;

  --grading-primary-1: #9999ff;
  --grading-primary-2: #66b3ff;
  --grading-secondary-1: #8080ff;
  --grading-secondary-2: #4d88ff;

  --light-green-husnegt:#EAFAF1 ;
  --light-blue-husnegt:#EBF5FB;

  --main-warning-orange:#fbab60;

}

body {
  background-color: var(--light);
  /* font-size: .9rem; */
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.deerees-zai {
  padding-top: 10vh;
}

.tab-zai {
  padding-top: 4vh;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.delgets-duuren {
  height: 85vh;
}

/* loader-iin css ehleh */
.loading-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
  background-color: #000;
  opacity: 50%;
  cursor: progress;
}

.loading-background .loading-bar {
  position: absolute;
  left: 50%;
  top: 40%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
}

.loading-background .loading-bar .loading-circle {
  border: 5px solid #fff;
  border-radius: 50%;
  border-top: 5px solid #000;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* loader css duusah */

/* nemelt */

/* The side navigation menu */
p {
  display: block;
  margin-block-start: .7em;
  margin-block-end: .7em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

i {
  display: block;
  margin-block-start: .7em;
  margin-block-end: .7em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.golluulah {
  display: block;
  margin-block-start: .7em;
  margin-block-end: .7em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

a {
  color: var(--main-blue);
}

a:link {
  text-decoration: none;
}
/* ::-webkit-scrollbar {
  display: none;
} */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
}
::-webkit-scrollbar-corner{  
  background: transparent;
}
/* Track */
::-webkit-scrollbar-track {
  /* background: var(--light); */
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: var(--light);  */
  /* background: var(--main-chosen-sidebar); */
  background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: var(--main-chosen-sidebar); */
  background: transparent;
}
.myHusnegt{
  overflow: scroll;
  /* scrollbar-width: 5px; */
}
.myHusnegt::-webkit-scrollbar-thumb {
  /* background: var(--light);  */
  background: var(--main-blue);
}

.modal{
  pointer-events: none;  
}
.modal .modal-body{
  max-height: 70vh;
  overflow-y: scroll;
  margin-right: 15px;
  margin-bottom: 15px;
}
.modal .modal-body::-webkit-scrollbar-thumb{  
  background: var(--main-chosen-sidebar);
}
.modal .tom-modal{
  max-width: 80vw;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
  text-align: center;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
  text-align: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.wrapper {
  position: relative;
  top: 0;
  /* height: 100vh; */
}

.card {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
  /* caret-color: white; */
  caret-color: var(--grading-primary-1);
  color: var(--main-dark-blue);
  margin-bottom: 20px;
  position: relative;
  border: 0 none;

  display: flex;
  flex-direction: column;
}

.card .card-header {
  background-color: transparent;
  padding: 15px 15px 0;
  border: 0;
}

.form-group {
  margin-bottom: 20px;
}

/* .form-control{
} */
.card a {
  color: var(--main-blue);
}

.card label {
  font-size: 0.86em;
  margin-bottom: 5px;
  color: #9A9A9A;
}
.form-group label{
  margin-left: 3px;
  margin-bottom: 3px;
}

.card form .form-group {
  margin-bottom: 20px;
}

.ungu-primary {
  background-image: linear-gradient(var(--grading-primary-1), var(--grading-primary-2));
  color: white
}

.ungu-primary:hover:not(.card) {
  background-image: linear-gradient(var(--grading-secondary-1), var(--grading-secondary-2));
  color: white
}

.btn {
  border-width: 2px;
  font-weight: 600;
  font-size: 0.8571em;
  line-height: 1.35em;
  text-transform: uppercase;
  border: none;
  margin: 10px 1px;
  border-radius: 3px;
  /* padding: 11px 22px; */
  cursor: pointer;
  background-image: linear-gradient(#66b3ff, #9999ff);
  /* background-color: #9999ff; */
  color: #FFFFFF;
  transition: all 150ms linear;
}

.btn-outline-primary {
  background: transparent;
  border: 2px solid #9999ff !important;
  color: #9999ff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.btn-sm {
  font-size: 0.8571em;
  border-radius: 3px;
  padding: 5px 15px;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  border: 0 none;
  background-color: transparent;
}
.modal{
  overflow-y: scroll;
}

.modal-header {
  border: none;
}

.table {
  width: 100%;
  /* margin-bottom: 2rem; */
  color: var(--main-dark-blue);
  border-collapse: collapse;
  text-indent: initial;
  /* border: 0 none; */
  vertical-align: middle;
}

.table-responsive {
  overflow: scroll;
  /* padding-bottom: 10px; */
}

.table th {
  color: var(--main-blue);
  text-transform: uppercase;
  vertical-align: middle;
  border: 0 none;
}

/* .table th{
  vertical-align: middle;
} */
.sidebar {
  position: fixed;
  top: 0;
  height: 100%;
  bottom: 0;
  width: 280px;
  left: 0;
  background-image: linear-gradient(#b3f0ff, #b3b3ff);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-left: 10px;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 18%) !important;
  font-size: 13.5px;
  line-height: 0.8;
  overflow-y: scroll;  
}

.sidebar i {
  margin-inline-end: 10px;
}

.sidebar .header {
  /* background-color: #fff; */
  position: relative;
  padding: 7px 0.7rem;

  text-transform: uppercase;
  white-space: nowrap;
  font-size: 1rem;
  text-align: center;

  padding-top: 2rem;
  height: 65px;
  margin-bottom: 10px;
}

.sidebar .header a {
  color: var(--main-dark-blue);
}

.sidebar li {
  width: 260px;
  display: block;
  /* padding-left: 5px; */
  text-transform: uppercase;
  white-space: nowrap;
}

.sidebar li a {
  color: var(--main-dark-blue);
}

.sidebar li.active {
  background-color: var(--main-chosen-sidebar);
  border-radius: 15px;
  font-weight: bold;
}

.sidebar li.active a {
  color: white;
}

/* Links on mouse-over */
.sidebar li:hover:not(.active) a {
  color: var(--main-dark-blue);
  font-weight: bold;
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
div.content {
  /* margin-left: 260px; */
  padding: 1px 16px;
  height: 1000px;
}



/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
/* @media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
} */

.main-panel {
  position: relative;
  float: right;
  width: calc(100% - 280px);
  /* transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1); */
}

.main-panel>.navbar {
  margin-bottom: 0;
}

.navbar {
  /* padding-top: 2rem; */
  /* padding-bottom: 1rem; */
  height: 65px;
  margin-bottom: 20px;
}

.navbar.navbar-transparent {
  background-color: transparent !important;
  box-shadow: none;
  border-bottom: 1px solid #ddd;
  margin-left: 15px;
  /* padding-left: 15px; */
}

.navbar.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
}

.navbar .navbar-wrapper {
  display: inline-flex;
  align-items: center;
}

.main-panel>.content {
  padding: 0 30px 30px;
  /* min-height: calc(100vh - 123px); */
  margin-top: 93px;

}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:focus {
  box-shadow: none;
}

/* .nav-link{
  display: flex;
  flex-direction: row;
} */
.neg-morond {
  display: flex;
  flex-direction: row;
}

.undsen-delgets {
  height: calc(100vh - 250px);
  width: 100%;
  overflow: scroll;
  background-color: #004080;
}

@media screen and (max-width: 991px) {
.modal{
  width: 90%;
  margin-left: 4%;
  margin-top: 5%;
}
  /* .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {float: left;}
  div.content {margin-left: 0;} */
  .sidebar {
    display: none;
  }
  .bgllgNer{
    display: none;
  }

  .main-panel {
    width: 100%;
  }

  .navbar.navbar-absolute {
    padding-top: 0;
  }

  .navbar .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }

  .navbar .navbar-toggler-bar {
    display: block;
    position: relative;
    width: 22px;
    height: 1px;
    border-radius: 1px;
    background: #66615B;
  }

}

@media screen and (min-width: 992px)and (max-width: 2500px) {

  .sidebar {
    display: block;
  }

  .main-panel {
    width: calc(100% - 280px);
  }

  .garahTovch{
    display: none;
  }

}

/* timeline-d */



.timeline-btn {
  border: 0 none;
  margin: 0;
  z-index: 5;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  .timeline-container{
    display: flex;
    flex-direction: row;
    width: "100%";
    overflow: scroll;
    padding-bottom: 15px;
  }
  .timetable {
    /* background-color: #b3f0ff; */
    /* margin 30px + morUrgun*2  +15px*/
    width: 125px;
    margin-right: 15px;
  }

  .tsag-shugam {
    display: flex;
    flex-direction: column;
    /* background-color: #93b3f3; */
  }

  .tsag-shugam-esreg {
    display: flex;
    flex-direction: row;
    /* background-color: #93b3f3; */
  }
}
@media screen and (min-width: 768px)and (max-width: 2500px) {
  .timeline-container{
    display: flex;
    flex-direction: column;
  }
  .timetable {
    /* background-color: #b3f0ff; */
    width: 100%;
  }
  
  .timetable ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: transparent;
  }
  .timetable ::-webkit-scrollbar-corner{  
    background: transparent;
  }
  /* Track */
  .timetable ::-webkit-scrollbar-track {
    /* background: var(--light); */
    background: transparent;
  }

  /* Handle */
  .timetable ::-webkit-scrollbar-thumb {
    /* background: var(--light);  */
    background: var(--main-chosen-sidebar);
    /* background: blue; */
  }

  /* Handle on hover */
  .timetable ::-webkit-scrollbar-thumb:hover {
    background: var( --grading-secondary-2);
    /* background: pink; */
  }





  
  .tsag-shugam {
    display: flex;
    flex-direction: row;
    /* background-color: #93b3f3; */
  }
  
  .tsag-shugam-esreg {
    display: flex;
    flex-direction: column;
    /* background-color: #93b3f3; */
  }
}
.text-bold{
  font-weight: bold;
}